import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic1.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic2.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic3.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic4.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic5.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic6.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic7.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic8.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic9.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic10.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic11.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic12.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic13.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic14.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic15.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic16.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/19-june-2/pic17.JPG",
];

export default function WorkshopOnReflections() {
  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">
          Workshop on 'Reflections on School Practices in alignment with NEP
          2020'
        </h1>

        <p>
          With an aim to reflect on the school practices and examine their
          alignment with National Education Policy 2020, Chinmaya Vidyalaya, New
          Delhi, conducted a professional development program for its staff
          members on 31st May 2023. Ms. Kalpana Kapoor, an eminent leadership
          coach and a mentor opened the day long session throwing light on the
          National Education Policy and the paradigm shift in education.
          <br />
          <br />
          She talked about the key principles of NEP 2020 and emphasized upon
          the importance of curriculum that must focus on the academic knowledge
          of the learners and also develop their skills, abilities and values
          for lifelong learning. She also shared the objectives to foster global
          citizenship and ultimately achieve sustainable development goals.
          <br />
          <br />
          Her inputs on pedagogies aimed at empowering teachers to adopt varied
          innovative teaching and learning approaches catering to the diverse
          needs of the learners, emphasizing on Competency based learning over
          rote learning and creating a conducive environment for joyful
          learning.
          <br />
          <br />
          The resource person also shared information about ‘SQAAF’- School
          Quality Assessment & Assurance Framework explaining about its seven
          domains.
          <br />
          <br />
          The staff of the Vidyalaya understood the key principles of NEP 2020
          and explored the methodologies to make classes more relevant
          for the learners.
        </p>
        <section className="kartavyapath__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
